<template>
    <!-- ContentTitle-->
    <div class="m-2 md:m-2 min-h-5 mb-5">
        <template v-if="(content.data.size==='h1')">
            <h1 :class="' min-h-15  '+ (editable ? 'cursor-pointer striped': '') +   (content.edit_mode && editable ? 'striped_no_hover': '')  "
                @click.passive="content.edit_mode=true">
                {{ content.data.text }}
            </h1>
        </template>
        <template v-if="(content.data.size==='h2')">
            <h2 :class="' min-h-15  '+ (editable ? 'cursor-pointer striped': '') +   (content.edit_mode && editable ? 'striped_no_hover': '')  "
                @click.passive="content.edit_mode=true">
                {{ content.data.text }}
            </h2>
        </template>
        <template v-if="(content.data.size==='h3')">
            <h3 :class="' min-h-15  '+ (editable ? 'cursor-pointer striped': '') +   (content.edit_mode && editable ? 'striped_no_hover': '')  "
                @click.passive="content.edit_mode=true">
                {{ content.data.text }}
            </h3>
        </template>
        <template v-if="(content.data.size==='h4')">
            <h4 :class="' min-h-15  '+ (editable ? 'cursor-pointer striped': '') +   (content.edit_mode && editable ? 'striped_no_hover': '')  "
                @click.passive="content.edit_mode=true">
                {{ content.data.text }}
            </h4>
        </template>
    </div>


</template>

<script>

export default {
    emits: ["clicked"], //
    name: "ContentTitle",
    components: {

    },
    props: {
        content: Object,
        contents: Object,
        editable:Boolean
    },
}
</script>
